<template>
  <div class="vg_wrapper">
    <el-tabs :value="'first'" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <AddHeader @submit="submit()" @cancel="cancel">
          <div slot="otherButton">
            <div class="flexHV vg_mr_8">
              <span>单据号：</span>
              <el-input disabled size="small" :value="quotation_no" style="width: 160px"></el-input>
            </div>
          </div>
          <div slot="specialStatus" style="display: flex">
            <div class="flexHV">
              <span>状态：</span>
              <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
            </div>
          </div>
        </AddHeader>
        <DynamicForm
          v-bind="$attrs"
          :total-width="mainForm.totalWidth"
          :split="mainForm.split"
          :form-list="mainForm.formProperties"
          ref="dynamicForm"
          class="dynamicForm"
          :is-show="false"
        />
        <div>
          <el-button class="vg_mb_8" type="primary" size="small" @click="SPFDialogVisible = true">导入</el-button>
          <el-button class="vg_mb_8" type="danger" size="small" @click="delRow()">删除</el-button>
        </div>
        <DynamicUTable
          ref="dynamicUTable"
          :tableData="subTableData"
          :columns="quotationAddSubTableProperties"
          :need-pagination="false"
          :is-show="false"
          @selection-change="val => (subSelections = val)"
          :row-click="(row, column, event) => (currentRow = row)"
          :computed-data="{ quotation_usd: quotation_usd }"
          :rowStyle="
            ({ row, rowIndex }) => {
              row.index = rowIndex;
              return { height: '80px' };
            }
          "
        >
          <template v-slot:default-boxGauge="scope"> {{ scope.row.boxgauge_length }}*{{ scope.row.boxgauge_width }}*{{ scope.row.boxgauge_height }}</template>
          <template v-slot:boxGauge="scope">
            <div style="display: flex">
              <el-input size="small" v-model="scope.row.boxgauge_length" @input="val => (scope.row.boxgauge_length = keep2Decimal(val))" />*
              <el-input size="small" v-model="scope.row.boxgauge_width" @input="val => (scope.row.boxgauge_width = keep2Decimal(val))" />*
              <el-input size="small" v-model="scope.row.boxgauge_height" @input="val => (scope.row.boxgauge_height = keep2Decimal(val))" />
            </div>
          </template>
        </DynamicUTable>
        <el-dialog title="导入样品核价单" :visible.sync="SPFDialogVisible" :top="0" width="70%" @opened="getSPFTableData">
          <div class="vg_mb_8">
            <el-button type="primary" size="small" @click="importSPFConfirm"> 确定选择</el-button>
            <el-button type="info" size="small" icon="el-icon-refresh-right" @click="SPFDialogRefresh()"> 刷新</el-button>
          </div>
          <search-table
            ref="SPFTable"
            :data="SPFTableData"
            :tableRowKey="'SPFDialogInfo'"
            :columns="SPFTableProperties"
            v-loading="SPFLoadFlag"
            :total-page="SPFTotalPage"
            :need-fixed-height="true"
            @selection-change="val => (SPFSelections = val)"
            @getTableData="getSPFTableData"
          >
          </search-table>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AddHeader from '@/views/component/addHeader.vue';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { quotationAPI } from '@api/modules/quotation';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { quotationEditSubTableProperties, quotationMainFormProperties, SPFTableProperties } from '@/views/DevelopManagement/QuotationManage/quotation';
import { deleteTableRow } from '@assets/js/arrayUtils';
import { getCptt, getCustomerInfo, getPort, getPriceTerms, getUnit } from '@api/public';
import { BigNumber } from 'bignumber.js';
import { keep2Decimal } from '@assets/js/regExUtil';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'QuotationAdd',
  components: { SearchTable, DynamicUTable, DynamicForm, AddHeader },
  data() {
    return {
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(quotationMainFormProperties)
      },
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      quotationAddSubTableProperties: cloneDeep(quotationEditSubTableProperties),
      formStatus: 0,
      quotation_no: '',
      quotation_id: '',
      SPFDialogVisible: false,
      SPFTableData: [],
      SPFTableProperties: cloneDeep(SPFTableProperties),
      SPFSelections: [],
      SPFLoadFlag: false,
      SPFTotalPage: 0,
      currentRow: {}
    };
  },
  computed: {
    quotation_usd() {
      let { sample_price, quotation_taxrate, quotation_accessories, quotation_freight, other_fees, currency_exchange, quotation_rates } = this.currentRow;
      let pre = new BigNumber(sample_price).div(quotation_taxrate).plus(quotation_accessories).plus(quotation_freight).plus(other_fees);
      let postCondition = new BigNumber(currency_exchange).times(quotation_rates).div(100);
      let temp = pre.div(postCondition);
      if (!temp.isFinite()) {
        this.currentRow.quotation_usd = 0;
        return '自动计算';
      }
      this.currentRow.quotation_usd = temp.toFixed(4);
      return temp.toFixed(4);
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    keep2Decimal,
    async initData() {
      await this.changeProperties();
    },
    async changeProperties() {
      this.mainForm.formProperties[0].options = await getCustomerInfo({ status: 2, type: 'name' });
      this.mainForm.formProperties[1].options = await getPriceTerms();
      this.mainForm.formProperties[2].options = await getCptt();

      this.quotationAddSubTableProperties.find(({ prop }) => prop === 'pord_departure').subItem.options = await getPort('select');
      let unit = await getUnit('select');
      this.quotationAddSubTableProperties.find(({ prop }) => prop === 'size_unit').subItem.options = unit;
      this.quotationAddSubTableProperties.find(({ prop }) => prop === 'prod_unit').subItem.options = unit;
    },
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$refs.dynamicForm.reset();
          await this.jump(`/quotation_list?perm_id=${this.$route.query.perm_id}`);
        })
        .catch(err => console.error(err));
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      quotationAPI.addQuotationOther(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.$refs.dynamicForm.reset();
        this.subTableData = [];
        const props = this.$route.query;
        this.jump('/quotation_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: props.perm_id,
              form_id: data.form_id
            })
          )
        });
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.quotation_id = this.quotation_id;
      formModel.quotation_no = this.quotation_no;
      formModel.quotationDetailList = this.subTableData;
      return formModel;
    },
    delRow() {
      let { tableData, map } = deleteTableRow('quotation_detail_id', this.subTableData, this.subTableMap, this.subSelections);
      this.subTableData = tableData;
      this.subTableMap = map;
    },
    async getSPFTableData() {
      this.SPFLoadFlag = true;
      await quotationAPI.getToDyjRqtaList(this.$refs.SPFTable.searchForm).then(({ data }) => {
        this.SPFTableData = data.list;
        this.SPFTotalPage = data.total;
        this.SPFLoadFlag = false;
      });
    },
    importSPFConfirm() {
      this.SPFSelections.forEach(row => {
        let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
        obj.boxgauge_length = row.smpl_box_l;
        obj.boxgauge_width = row.smpl_box_w;
        obj.boxgauge_height = row.smpl_box_h;
        obj.prod_size = row.smpl_spec;
        obj.prod_no = row.smpl_no;
        obj.sample_price = row.quta_total;
        obj.box_num = row.smpl_qpc;
        obj.quotation_taxrate = 1.13;
        obj.imge_url = row.imge_url;
        this.subTableData.push(obj);
      });
      this.SPFDialogVisible = false;
    },
    SPFDialogRefresh() {
      this.$refs.SPFTable.resetFields();
      this.getSPFTableData();
    }
  }
};
</script>

<style scoped>
::v-deep .elx-table .elx-body--column.col--ellipsis > .elx-cell {
  max-height: 80px;
}
</style>
